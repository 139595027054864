<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  background-color: aliceblue;
}
a{
  text-decoration: none;
}
li{
  list-style: none;
}
</style>
