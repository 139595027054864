<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <!-- 头部组件 -->
    <Header></Header>
    <form class="form">
      <div class="flex">
        <img :src="this.user.ava" alt />
      </div>
      <div class="flex">
        <label>账号</label>
        <input type="text" :value="this.user.name" />
      </div>
      <div class="flex">
        <label>密码</label>
        <input type="password" :value="this.user.password" />
      </div>
      <div class="flex">
        <label>邮箱</label>
        <input type="text" :value="this.user.email" />
      </div>
      <div class="flex">
        <label>地址</label>
        <input type="text" :value="this.user.address" />
      </div>
      <div class="flex">
        <label>手机号</label>
        <input type="text" :value="this.user.phone" />
      </div>
      <div class="flex">
        <button>提交</button>
      </div>
    </form>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "User",
  data() {
    return {
      user: {}
    };
  },
  components: {
    Header
  },
  created() {
    // 登录时已经把用户信息存储到浏览器缓存里，现在以对象形式拿出来
    this.user = JSON.parse(localStorage.getItem('userinfo'))
  }
};
</script>

<style scoped>
form {
  border: 1px solid #ccc;
  margin: 20px auto;
  padding: 20px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #555;
  width: 1000px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.flex>img{
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
    border-radius: 50%;
}
input[type="text"],
input[type="password"] {
  width: 50%;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  margin-bottom: 20px;
  border-radius: 15px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #555;
}
button {
  width: 50%;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #4caf50;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  cursor: pointer;
}
button:hover {
  background-color: #38a048;
}
</style>