<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="recommend">
      <div class="recommend-top">
        <h1>video</h1>
      </div>
      <div class="width">
        <div class="width-top">
          <!-- <a href="#">
            <img src="../assets/img/nov.jpg" alt />
          </a> -->
          <video controls width="640" height="360">
            <source src="../assets/vedio/video1.mp4" type="video/mp4">
            戀愛視頻
          </video>
        </div>
        <!-- <div class="width-top-right">
          <a href="#" >
            <img src="../assets/img/gt3-550.jpg" alt />
          </a>
        </div> -->
        <h1 style="textalign:center;">尤克里里彈唱</h1>
        <div class="width-center">
          <!-- <a href="#">
            <img src="../assets/img/matestation-p.jpg" alt />
          </a> -->
          
                
          <video controls width="280" height="360">
            <source src="../assets/vedio/ukll.mp4" type="video/mp4">
      
          </video>

        </div>


        <div class="width-center-right">
          <!-- <a href="#">
            <img src="../assets/img/matebook-e.jpg" alt />
          </a> -->
                  <div class="width-center">
          <!-- <a href="#">
            <img src="../assets/img/matestation-p.jpg" alt />
          </a> -->
          
                <h1 style="textalign:center;">地鐵合影</h1>
          <video controls width="640" height="360">
            <source src="../assets/vedio/ditie1.mp4" type="video/mp4">
      
          </video>
        </div>
        </div>
        <!-- <div class="width-bottom">
          <a href="#">
            <img src="../assets/img/live.gif" alt />
          </a>
        </div> -->
        <!-- <div class="width-bottom">
          <a href="#">
            <img src="../assets/img/6g.jpg" alt />
          </a>
        </div> -->
        <div class="width-bottom width-bottom-first">
          <a href="#">
            <img src="../assets/img/psc.png" alt />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Recommend"
};
</script>

<style scoped>
.recommend {
  width: 85%;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 30px;
}
.recommend .width {
  width: 1100px;
  margin: 0 auto;
}
.recommend-top {
  margin: 50px auto;
  text-align: center;
}
.recommend-top h1 {
  color: #333333;
  font-size: 30px;
}
.recommend-top div {
  width: 40px;
  height: 2px;
  background: #de0000;
  margin: 0 auto;
  margin-top: 5px;
}

.recommend .width-top {
  display: inline-block;
  width: 690px;
  height: 333px;
  position: relative;
  overflow: hidden;
  margin-right: 25px;
}
.recommend .width-top img {
  width: 690px;
}
.recommend .width-top-right {
  display: inline-block;
  width: 330px;
  height: 333px;
  position: relative;
  overflow: hidden;
}
.recommend .width-top-right img {
  width: 340px;
}
.recommend .width-center {
  display: inline-block;
  /* width: 330px; */
  /* height: 333px; */
  width: 500px;
  height: 500px;
  position: relative;
  overflow: hidden;
  margin-top: 30px;
}
.recommend .width-center img {
  width: 340px;
}
.recommend .width-center-right {
  display: inline-block;
  width: 690px;
  height: 333px;
  position: relative;
  overflow: hidden;
  margin-left: 25px;
  margin-top: 30px;
}
.recommend .width-center-right img {
  width: 690px;
}
.recommend .width-bottom {
  display: inline-block;
  width: 330px;
  height: 233px;
  position: relative;
  overflow: hidden;
  margin-right: 25px;
  margin-top: 30px;
}
.recommend .width-bottom img {
  width: 330px;
}
.recommend .width-bottom-first {
  margin-right: 0;
}
</style>