<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container">
    <div class="wrap">
      <article>
        <section>
          <aside>
            <em>
              <img src="../assets/img/user.png" />
            </em>
            <form action>
              <p class="name">
                <i></i>
                <input type="text" v-model="userinfo.username" placeholder="请输入用户名" />
              </p>
              <p class="password">
                <i></i>
                <input type="password" v-model="userinfo.password" placeholder="请输入密码" />
              </p>
              <button @click="login">登录</button>
              <p class="regist">
                <span>没有账号?</span>
                <a href="#">立即注册</a>
              </p>
              <div class="clear"></div>
            </form>
          </aside>
        </section>
      </article>
      <footer>
        <ul>
          <li>
            <a href="#">联系我们</a>
          </li>
          <li>
            <a href="#">关于我们</a>
          </li>
          <li>
            <a href="#">人才招聘</a>
          </li>
          <li>
            <a href="#">友情链接</a>
          </li>
          <li>
            <a href="#">公司地址</a>
          </li>
          <li>
            <a href="#">关注我们</a>
          </li>
        </ul>
        <p>本网站版权归xxx所有，未经许可，不得转载。</p>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userinfo:{
        username: '',
        password: ''
      },
      user:{
        name:"user",
        password: 'user',
        ava: require('../assets/img/2.jpeg'),
        phone:'15678168869',
        email:'258789247@qq.com',
        address:'桂林',
        money:'1025.25'
      }
    }
  },
  methods:{
    login(){
      console.log(this.userinfo.username);
      if(this.userinfo.username == '' || this.userinfo.password == ''){
        alert("请输入用户名和密码")
      } else if(this.userinfo.username != this.user.name | this.userinfo.password != this.user.password){
        alert("用户名或密码错误，请重新输入");
      } else {
        // 账号密码输入正确后携带query参数跳转首页
        this.$router.push({
          path:'/',
          query:this.user
        })
        // 用户数据以string方式存储到浏览器中
        localStorage.setItem('userinfo', JSON.stringify(this.user))
      }
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
html,
body,
.container,
.wrap {
  width: 100%;
  height: 100%;
}
em {
  font-style: normal;
}
a {
  display: inline-block;
  text-decoration: none;
  color: #000;
}
li {
  list-style: none;
  display: inline-block;
  border-right: 1px solid #000;
}
.clear {
  clear: both;
}
header,
footer,
section {
  width: 1300px;
  margin: 0 auto;
}

header,
footer {
  height: 100px;
}

/*头部样式*/
header em {
  font-size: 50px;
  height: 100px;
  line-height: 100px;
}
header span {
  font-size: 22px;
  margin-left: 20px;
}

article {
  width: 100%;
  height: 600px;
  background-color: #00a0e9;
}
section {
  height: 600px;
  background-image: url("../assets/img/bg.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: relative;
}

/*表单的样式*/
aside {
  width: 400px;
  height: 450px;
  position: absolute;
  top: 70px;
  right: 100px;
  border-radius: 15px;
}
aside em {
  display: block;
  width: 60px;
  height: 60px;
  margin: 25px auto 10px auto;
}
aside em img {
  width: 100%;
  height: auto;
}
form {
  width: 300px;
  margin: 0 auto;
}
form p {
  position: relative;
  margin-top: 10px;
}
form input {
  padding-left: 50px;
  width: 250px;
  height: 40px;
  border: 1px solid #47414133;
  margin-top: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.82);
  outline: none;
}
form i {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
}
.name i {
  background-image: url("../assets/img/user1.png");
  background-repeat: no-repeat;
  left: 10px;
  top: 27px;
}
.password i {
  background-image: url("../assets/img/password1.png");
  background-repeat: no-repeat;
  left: 10px;
  top: 29px;
}
button {
  background-color: #00a0e9;
  border: none;
  width: 100%;
  height: 40px;
  letter-spacing: 3px;
  font-size: 20px;
  margin-top: 35px;
  border-radius: 10px;
  color: #fff;
  outline: none;
}
.regist {
  float: left;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  margin-top: 20px;
}
.regist {
  width: 130px;
}
.regist a {
  color: red;
}
/*尾部样式*/
footer {
  margin-top: 15px;
}
footer ul {
  width: 68%;
  margin: 0 auto;
}
footer ul li {
  width: 120px;
  text-align: center;
  font-size: 13px;
  height: 15px;
  line-height: 15px;
}
footer p {
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
}
</style>