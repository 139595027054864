<!-- eslint-disable vue/multi-word-component-names ../assets/img/logo.png -->
<template>
  <div class="nav-con">
    <div class="nav">
      <a href="#">
        <!-- <img src="../assets/img/huawei_logo.png" alt /> -->
        <img src="../assets/img/jiaran2.png" alt />
      </a>
      <ul class="cont">
        <!-- <li @click="index">
          <span>首页</span>
        </li>
        <li @click="person">
          <span>个人中心</span>
        </li>
        <li>
          <span>家庭产品</span>
        </li> -->
        <li>
          <h1>dyt♡(*´∀｀*)人(*´∀｀*)♡zmy</h1>
        </li>
        <!-- <li>
          <span>服务支持</span>
        </li>
        <li>
          <span>合作伙伴</span>
        </li>
        <li>
          <span>关于华为</span>
        </li> -->
        <!-- <li @click="exit">
          <span>退出登录</span>
        </li> -->
      </ul>
      <a href="#">
        <!-- <img src="../assets/img/huawei_logo.png" alt /> -->
        <img src="../assets/img/jiaran1.png" alt />
      </a>
    <!-- <div>
          <a href="#">
        <img src="../assets/img/psc.png"  alt />
      </a>
    </div> -->
    </div>

  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  data() {
    return {
      user:{}
    }
  },
  mounted(){
    // 使用生命周期获取登录数据
    this.user = this.$route.query
  },
  methods:{
    // 跳转首页
    index(){
      this.$router.push({
        path: '/'
      })
    },

    // 跳转个人中心,并携带user参数
    person(){
      this.$router.push({
        path: '/user',
        query: this.user
      })
    },

    // 退出登录
    exit(){
      // 回到登录页面并清除浏览器数据
      localStorage.removeItem('userinfo')
      this.$router.push({
        path:'/login',
      })
    }
  }
};
</script>

<style scoped>
.nav-con {
  position: relative;
  border-bottom: 1px solid rgb(153, 149, 149);
}
.nav {
  width: 1174.9px;
  /* height: 78px; */
    height: 238px;
  margin: 0 auto;
  position: relative;
  line-height: 78px;
}
.nav a {
  float: left;
  display: block;
  /* width: 73px;
  height: 78px; */
    width: 163px;
  height: 238px;
}
.nav a img {
  width: 163px;
  vertical-align: middle;
}
.nav .cont {
  margin-left: 275px;
}
.nav .cont > li {
  float: left;
  margin-right: 25px;
  padding: 5px 5px 0 5px;
  box-sizing: border-box;
  height: 78px;
}
.nav .cont > li:hover {
  border-bottom: 3px solid rgb(219, 182, 32);
}
.cont li > span {
  color: #333333;
  font-size: 14.4px;
  cursor: pointer;
}
</style>