import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import Login from '../views/Login.vue'
import Index from '../views/Index.vue'
import User from '../views/User.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: Index
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/user',
    name: 'user',
    component: User
  }
]

const router = new VueRouter({
  routes
})

export default router
