<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <!-- 轮播图 -->
    <Banner></Banner>

    <!-- 推荐信息 -->
    <Recommend></Recommend>

    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
import Banner from '../components/Banner.vue'
import Recommend from '../components/Recommend.vue'
import Footer from '../components/Footer.vue'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name:'Index',
    components:{
        // eslint-disable-next-line vue/no-unused-components
        Header,
        Banner,
        Recommend,
        Footer
    }
}
</script>

<style>

</style>