<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="footer">
      <div class="footer-bottom">
        <ul>
          <li>
            <a href="#">個人空間</a>
          </li>
          <li>
            <a href="#">前段小白自學</a>
          </li>
          <li class="cut">|</li>
          <li>
            <a href="#">在嘗試學習部署前段</a>
          </li>
          <li>
            <a href="http://www.zhaomingyue.cn">zhaomingyue.cn</a>
          </li>
          <li class="cut">|</li>
          <li>
            <a href="http://beian.miit.gov.cn">粤ICP备2024341632号-1</a>
          </li>
          <!-- <li>
            <a href="#">除名查询</a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer"
};
</script>

<style scoped>
.footer{
    width: 100%;
    margin-top: 20px;
}
/* 页脚 */
.footer-bottom{
    width: 100%;
    height: 50px;
    line-height: 50px;
}
.footer-bottom ul{
    margin: 0 auto;
    height: 100%;
    width: 1153px;
    padding-left: 138px;
}
.footer-bottom ul li{
    float: left;
    margin-right: 20px;
}
.footer-bottom ul li a{
    color: black;
}
</style>