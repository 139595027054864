<!-- eslint-disable vue/multi-word-component-names -->
<template>
<!-- 轮播图 -->
  <div>
    <div class="Rotation">
      <ul class="first">
        <li>
          <img src="../assets/img/hezhao1.jpeg" alt />
          <img src="../assets/img/hezhao2.jpeg" alt />
          <img src="../assets/img/muma.png" alt />
          <!-- <div>dyt❥zmy</div> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Banner"
};
</script>

<style scoped>
.Rotation {
  width: 1263.3px;
  position: relative;
  height: 425px;
  margin: 0 auto;
}
.Rotation .first {
  position: absolute;
  top: 0;
  left: 0;
  width: 500%;
}
.Rotation .first li {
  float: left;
  position: relative;
  height: 425.71px;
  /* height: 600.71px; */
}
.Rotation .first li img {
  height: 425.71px;
  /* height: 600.71px; */
}
.Rotation .first div {
  position: absolute;
  float: left;
  left: 16%;
  width: 170px;
  height: 40px;
  top: 300px;
  border: 1px solid #000000;
  cursor: pointer;
  background: rgba(239, 239, 239, 0.5);
  text-align: center;
  line-height: 40px;
}
.Rotation .first div:hover {
  background-color: red;
  color: #fff;
  border: none;
}
</style>